import {
    inject,
    Injectable,
    isDevMode,
    makeStateKey,
    Renderer2,
    RendererFactory2,
    TransferState,
    Type,
} from '@angular/core';
import Client, {
    ISbStories,
    ISbStoriesParams,
    ISbStory,
    ISbStoryData,
    ISbStoryParams,
    ISbComponentType,
    SbHelpers
} from 'storyblok-js-client';
import { STORYBLOK_CONFIG } from './storyblok.provider';
import { DOCUMENT } from '@angular/common';
import {
    MissingComponentStroyblokError,
    StroyblokApiError,
} from './storyblok.errors';
import { ISbDataSourceEntries, ISbDataSourceEntry } from './storyblok.types';
import { PlatformService, WINDOW } from '@seven1/angular/ssr';
import { Data } from '@angular/router';
import { richTextResolver } from '@storyblok/richtext';

const storiesKey = makeStateKey<ISbStories>(`stories`);
const fallback_stories = {
    data: { cv: 0, links: [], rels: [], stories: [] },
    perPage: 0,
    total: 0,
    headers: undefined,
};

@Injectable({
    providedIn: 'root',
})
export class StoryblokService {
    private _renderer?: Renderer2;
    private _config = inject(STORYBLOK_CONFIG);
    private _platform = inject(PlatformService);
    private _transferState = inject(TransferState);
    private _window = inject(WINDOW);
    private _document = inject(DOCUMENT);
    public helpers = new SbHelpers();
    public richTextResolver = richTextResolver(this._config.richText);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bridge?: any;
    private _client = new Client(this._config);

    constructor(rendererFactory: RendererFactory2) {
        this._renderer = rendererFactory.createRenderer(null, null);
    }

    /**
     * Load storyblok bridge - check if there is a script with the id `storyblokBridge`:
     * - if yes, call `callback` with `onInput`
     * - if no, create it and then call `callback` with `onInput`
     *
     * @param callback - todo
     * @param onInput - todo
     * */
    private _loadBridge(
        callback: (onInput: (data: unknown) => void) => void,
        onInput: (data: unknown) => void,
    ): void {
        const existingScript =
            this._document?.getElementById('storyblokBridge');
        if (!existingScript) {
            const script = this._renderer?.createElement('script');
            if (script) {
                script.src = '//app.storyblok.com/f/storyblok-v2-latest.js';
                script.id = 'storyblokBridge';
                this._renderer?.appendChild(this._document.body, script);
                script.onload = () => {
                    if (isDevMode()) console.log('Loaded Story bridge script');
                    callback(onInput);
                };
            }
        } else {
            callback(onInput);
        }
    }

    /**
     * Init storyblok bridge - get bridge from `window` handle events:
     * - **`change`, `publish`:** reload location to update story
     * - **`input`:** todo: reload via api directly (draft or published, depending if in editor)
     *
     * @param self - instance of the `StoryblokService`
     * @param onInput - todo
     * */
    private _initBridge(
        self: StoryblokService,
        onInput: (data: unknown) => void,
    ): void {
        // @ts-expect-error StoryblokBridge should be part of window when bridge is inited
        const { StoryblokBridge, location } = this._window;
        self.bridge = new StoryblokBridge();
        self.bridge.on(['published', 'change'], (event: {slugChanged: boolean}) => {
            if (!event.slugChanged) {
                location.reload();
            }
        });
        self.bridge.on(['input'], (event: unknown) => {
            onInput(event);
        });
        // todo - use in api calls directly
        self.bridge.pingEditor(() => {
            if (self.bridge.isInEditor()) {
                //  load the draft version
            } else {
                // load the published version
            }
        });
        if (isDevMode()) console.log('Storyblok editor initialized');
    }

    /**
     * Load and init bridge, if we are inside storyblok
     *
     * */
    initEditor(onInput: (data: Data | unknown) => void): void {
        if (
            this._platform.isBrowser &&
            this._window?.location.search.includes('_storyblok')
        ) {
            if (isDevMode()) console.info('Storyblok bridge init');
            this._loadBridge(
                onInput => this._initBridge(this, onInput),
                onInput,
            );
        }
    }

    /**
     * Fetch stories of storyblok with `ISbStoriesParams` (search, pagination, filtering, sorting)
     *
     * @link https://www.storyblok.com/docs/api/content-delivery/v1#core-resources/stories/stories
     * @param parameter - `ISbStoriesParams`
     * @returns a `CmsStories` or null, if not existent
     * */
    async getStories(parameter: ISbStoriesParams): Promise<ISbStories> {
        let res: ISbStories;
        if (this._platform.isServer) {
            res = await this._client.getStories(parameter);
            this._transferState.set(storiesKey, res);
        } else {
            //  if (isPlatformBrowser(this.platformId))
            if (this._transferState.hasKey(storiesKey)) {
                res = this._transferState.get(storiesKey, fallback_stories);
            } else {
                res = await this._client.getStories(parameter);
            }
        }
        return res;
    }

    /**
     * Fetch a single story from Storyblok with `ISbStoryParams` (find_by, version, resolve_links, resolve_relations, language)
     *
     * @param slug - param appended to the stories url `/v2/cdn/stories/(:full_slug|:id|:uuid)`
     * @param parameter - `ISbStoryParams`
     * @returns a `ISbStoryData` or null, if not existent
     * */
    public async getStory<
        T extends ISbComponentType<string> = ISbComponentType<string>,
    >(slug: string, parameter?: ISbStoryParams): Promise<ISbStoryData<T>> {
        const storyKey = makeStateKey<ISbStoryData>('story/' + slug);
        parameter = parameter || {
            version: this._config.version,
        };

        this.bridge?.pingEditor(() => {
            if (this.bridge.isInEditor()) {
                //  load the draft version
                parameter = { ...parameter, version: 'draft' };
            }
        });

        let res: ISbStory;
        let storyData: ISbStoryData<T>;
        if (this._platform.isServer || !this._transferState.hasKey(storyKey)) {
            if (isDevMode()) console.log('getStory from API', slug, parameter);

            res = await this._client.getStory(slug, parameter);
            storyData = res.data.story as ISbStoryData<T>;
            this._transferState.set(storyKey, storyData);
        } else {
            storyData = this._transferState.get(
                storyKey,
                undefined,
            ) as ISbStoryData<T>;
        }

        return storyData;
    }

    /**
     * Fetch
     *
     * @param datasource - slug of the datasource, `datasource` param appended to the datasource url `/v2/cdn/datasource_entries?datasource=${datasource}`
     * @returns a `ISbDataSourceEntry[]` or an empty array, if not existent
     * */
    public async getDatasourceEntries(
        datasource: string,
    ): Promise<ISbDataSourceEntry[] | null> {
        const dataKey = makeStateKey<ISbDataSourceEntry[]>(
            'datasource/' + datasource,
        );

        let res: ISbDataSourceEntries;
        let data: ISbDataSourceEntry[] | null = null;
        if (this._platform.isServer || !this._transferState.hasKey(dataKey)) {
            try {
                res = (await this._client.get('cdn/datasource_entries', {
                    datasource,
                })) as ISbDataSourceEntries;
            } catch (e) {
                throw new StroyblokApiError(
                    'cdn/datasource_entries?datasource=' + datasource,
                    e,
                );
            }
            if (res) {
                data = res.datasource_entries;
                this._transferState.set(dataKey, data);
            }
        } else {
            //  if (isPlatformBrowser(this.platformId))
            data = this._transferState.get(dataKey, []);
        }
        return data;
    }

    /**
     * Resolve a component with a component name
     *
     * @param component - the name / key of the component, configured in the Storyblok config
     * @returns a component class of `Type<T>`
     * */
    public resolveComponent<T = unknown>(component: string): Type<T> {
        const res = this._config.components[component];
        if (!res) {
            throw new MissingComponentStroyblokError(component);
        }
        return res as Type<T>;
    }

    /**
     * Set an element editable, setting attributes `data-blok-c` and `data-blok-uid`
     * with the json from the `editableString`
     *
     * @param nativeElement - the native element, where the attributes should be set
     * @param editableString - the storyblok `editable` string (includes `<!--#storyblok# ... -->`)
     * */
    public setElementEditable<E = unknown>(
        nativeElement: E,
        editableString: string,
    ): void {
        if (nativeElement && this._renderer && editableString) {
            const options = JSON.parse(
                editableString
                    .replace('<!--#storyblok#', '')
                    .replace('-->', ''),
            );
            this._renderer.setAttribute(
                nativeElement,
                'data-blok-c',
                JSON.stringify(options),
            );
            this._renderer.setAttribute(
                nativeElement,
                'data-blok-uid',
                options.id + '-' + options.uid,
            );
        }
    }
}
