import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { inject, InjectionToken, PLATFORM_ID } from '@angular/core';

export const WINDOW = new InjectionToken<Window & typeof globalThis | null>(
    'WINDOW - An abstraction over global window object for ssr purpose',
    {
        providedIn: 'root',
        factory: () => {
            const platform = inject(PLATFORM_ID);

            if (isPlatformBrowser(platform)) {
                return window;
            }

            // Return a mock window object for SSR
            return {
                document: inject(DOCUMENT),
                location: {},
                navigator: {},
                localStorage: {},
                sessionStorage: {},
                // Add other window properties as needed
            } as Window & typeof globalThis;
        },
    },
);
