/**
 * name: @storyblok/richtext
 * (c) 2024
 * description: Storyblok RichText Resolver
 * author: Alvaro Saburido <hola@alvarosaburido.dev> (https://github.com/alvarosabu/)
 */
function p(e, r) {
  if (!r) return {
    src: e,
    attrs: {}
  };
  let a = 0,
    R = 0;
  const m = {},
    l = [];
  function g(n, $, S, E, O) {
    typeof n != "number" || n <= $ || n >= S ? console.warn(`[StoryblokRichText] - ${E.charAt(0).toUpperCase() + E.slice(1)} value must be a number between ${$} and ${S} (inclusive)`) : O.push(`${E}(${n})`);
  }
  if (typeof r == "object") {
    if (typeof r.width == "number" && r.width > 0 ? (m.width = r.width, a = r.width) : console.warn("[StoryblokRichText] - Width value must be a number greater than 0"), r.height && typeof r.height == "number" && r.height > 0 ? (m.height = r.height, R = r.height) : console.warn("[StoryblokRichText] - Height value must be a number greater than 0"), r.loading && ["lazy", "eager"].includes(r.loading) && (m.loading = r.loading), r.class && (m.class = r.class), r.filters) {
      const {
          filters: n
        } = r || {},
        {
          blur: $,
          brightness: S,
          fill: E,
          format: O,
          grayscale: h,
          quality: v,
          rotate: T
        } = n || {};
      $ && g($, 0, 100, "blur", l), v && g(v, 0, 100, "quality", l), S && g(S, 0, 100, "brightness", l), E && l.push(`fill(${E})`), h && l.push("grayscale()"), T && [0, 90, 180, 270].includes(r.filters.rotate || 0) && l.push(`rotate(${T})`), O && ["webp", "png", "jpeg"].includes(O) && l.push(`format(${O})`);
    }
    r.srcset && (m.srcset = r.srcset.map(n => {
      if (typeof n == "number") return `${e}/m/${n}x0/${l.length > 0 ? `filters:${l.join(":")}` : ""} ${n}w`;
      if (Array.isArray(n) && n.length === 2) {
        const [$, S] = n;
        return `${e}/m/${$}x${S}/${l.length > 0 ? `filters:${l.join(":")}` : ""} ${$}w`;
      } else {
        console.warn("[StoryblokRichText] - srcset entry must be a number or a tuple of two numbers");
        return;
      }
    }).join(", ")), r.sizes && (m.sizes = r.sizes.join(", "));
  }
  let I = `${e}/m/`;
  return a > 0 && R > 0 && (I = `${I}${a}x${R}/`), l.length > 0 && (I = `${I}filters:${l.join(":")}`), {
    src: I,
    attrs: m
  };
}
var u = /* @__PURE__ */(e => (e.DOCUMENT = "doc", e.HEADING = "heading", e.PARAGRAPH = "paragraph", e.QUOTE = "blockquote", e.OL_LIST = "ordered_list", e.UL_LIST = "bullet_list", e.LIST_ITEM = "list_item", e.CODE_BLOCK = "code_block", e.HR = "horizontal_rule", e.BR = "hard_break", e.IMAGE = "image", e.EMOJI = "emoji", e.COMPONENT = "blok", e))(u || {}),
  f = /* @__PURE__ */(e => (e.BOLD = "bold", e.STRONG = "strong", e.STRIKE = "strike", e.UNDERLINE = "underline", e.ITALIC = "italic", e.CODE = "code", e.LINK = "link", e.ANCHOR = "anchor", e.STYLED = "styled", e.SUPERSCRIPT = "superscript", e.SUBSCRIPT = "subscript", e.TEXT_STYLE = "textStyle", e.HIGHLIGHT = "highlight", e))(f || {}),
  C = /* @__PURE__ */(e => (e.TEXT = "text", e))(C || {}),
  G = /* @__PURE__ */(e => (e.SELF = "_self", e.BLANK = "_blank", e))(G || {}),
  L = /* @__PURE__ */(e => (e.URL = "url", e.STORY = "story", e.ASSET = "asset", e.EMAIL = "email", e))(L || {});
const z = ["area", "base", "br", "col", "embed", "hr", "img", "input", "link", "meta", "param", "source", "track", "wbr"],
  k = (e = {}) => Object.keys(e).map(r => `${r}="${e[r]}"`).join(" "),
  K = (e = {}) => Object.keys(e).map(r => `${r}: ${e[r]}`).join("; ");
function F(e) {
  return e.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
}
const _ = e => Object.fromEntries(Object.entries(e).filter(([r, a]) => a !== void 0));
function w(e, r = {}, a) {
  const R = k(r),
    m = R ? `${e} ${R}` : e,
    l = Array.isArray(a) ? a.join("") : a || "";
  if (e) {
    if (z.includes(e)) return `<${m}>`;
  } else return l;
  return `<${m}>${l}</${e}>`;
}
function Y(e = {}) {
  let r = 0;
  const {
      renderFn: a = w,
      textFn: R = F,
      resolvers: m = {},
      optimizeImages: l = !1,
      keyedResolvers: g = !1
    } = e,
    I = a !== w,
    n = t => i => {
      const s = i.attrs || {};
      return g && (s.key = `${t}-${r}`), a(t, s, i.children || null);
    },
    $ = t => {
      const {
        src: i,
        alt: s,
        title: c,
        srcset: d,
        sizes: o
      } = t.attrs || {};
      let b = i,
        A = {};
      if (l) {
        const {
          src: U,
          attrs: D
        } = p(i, l);
        b = U, A = D;
      }
      g && (A = {
        ...A,
        key: `img-${r}`
      });
      const P = {
        src: b,
        alt: s,
        title: c,
        srcset: d,
        sizes: o,
        ...A
      };
      return a("img", _(P));
    },
    S = t => {
      const {
          level: i,
          ...s
        } = t.attrs || {},
        c = {
          ...s
        };
      return g && (c.key = `h${i}-${r}`), a(`h${i}`, c, t.children);
    },
    E = t => {
      var c, d, o, b;
      const i = a("img", {
          src: (c = t.attrs) == null ? void 0 : c.fallbackImage,
          alt: (d = t.attrs) == null ? void 0 : d.alt,
          style: "width: 1.25em; height: 1.25em; vertical-align: text-top",
          draggable: "false",
          loading: "lazy"
        }),
        s = {
          "data-type": "emoji",
          "data-name": (o = t.attrs) == null ? void 0 : o.name,
          "data-emoji": (b = t.attrs) == null ? void 0 : b.emoji
        };
      return g && (s.key = `emoji-${r}`), a("span", s, i);
    },
    O = t => a("pre", {
      ...t.attrs,
      key: `code-${r}`
    }, a("code", {
      key: `code-${r}`
    }, t.children || "")),
    h = (t, i = !1) => ({
      text: s,
      attrs: c
    }) => {
      const {
          class: d,
          id: o,
          ...b
        } = c || {},
        A = i ? {
          class: d,
          id: o,
          style: K(b) || void 0
        } : c || {};
      return g && (A.key = `${t}-${r}`), a(t, _(A), s);
    },
    v = t => N(t),
    T = t => {
      const {
        marks: i,
        ...s
      } = t;
      return "text" in t ? i ? i.reduce((c, d) => v({
        ...d,
        text: c
      }),
      // Fix: Ensure render function returns a string
      v({
        ...s,
        children: s.children
      })
      // Fix: Cast children to string
      ) : R(s.text) : "";
    },
    y = t => {
      const {
        linktype: i,
        href: s,
        anchor: c,
        ...d
      } = t.attrs || {};
      let o = "";
      switch (i) {
        case L.ASSET:
        case L.URL:
          o = s;
          break;
        case L.EMAIL:
          o = `mailto:${s}`;
          break;
        case L.STORY:
          o = s, c && (o = `${o}#${c}`);
          break;
        default:
          o = s;
          break;
      }
      const b = {
        ...d
      };
      return o && (b.href = o), g && (b.key = `a-${r}`), a("a", b, t.text);
    },
    x = t => {
      var i, s;
      return console.warn("[StoryblokRichtText] - BLOK resolver is not available for vanilla usage"), a("span", {
        blok: (i = t == null ? void 0 : t.attrs) == null ? void 0 : i.body[0],
        id: (s = t.attrs) == null ? void 0 : s.id,
        key: `component-${r}`,
        style: "display: none"
      });
    },
    H = new Map([[u.DOCUMENT, n("")], [u.HEADING, S], [u.PARAGRAPH, n("p")], [u.UL_LIST, n("ul")], [u.OL_LIST, n("ol")], [u.LIST_ITEM, n("li")], [u.IMAGE, $], [u.EMOJI, E], [u.CODE_BLOCK, O], [u.HR, n("hr")], [u.BR, n("br")], [u.QUOTE, n("blockquote")], [u.COMPONENT, x], [C.TEXT, T], [f.LINK, y], [f.ANCHOR, y], [f.STYLED, h("span", !0)], [f.BOLD, h("strong")], [f.TEXT_STYLE, h("span", !0)], [f.ITALIC, h("em")], [f.UNDERLINE, h("u")], [f.STRIKE, h("s")], [f.CODE, h("code")], [f.SUPERSCRIPT, h("sup")], [f.SUBSCRIPT, h("sub")], [f.HIGHLIGHT, h("mark")], ...Object.entries(m).map(([t, i]) => [t, i])]);
  function j(t) {
    r += 1;
    const i = H.get(t.type);
    if (!i) return console.error("<Storyblok>", `No resolver found for node type ${t.type}`), "";
    if (t.type === "text") return i(t);
    const s = t.content ? t.content.map(N) : void 0;
    return i({
      ...t,
      children: s
      // Fix: Update the type of 'children' to Node[]
    });
  }
  function N(t) {
    return t.type === "doc" ? I ? t.content.map(j) : t.content.map(j).join("") : Array.isArray(t) ? t.map(j) : j(t);
  }
  return {
    render: N
  };
}
export { u as BlockTypes, G as LinkTargets, L as LinkTypes, f as MarkTypes, C as TextTypes, Y as richTextResolver };