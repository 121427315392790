import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class PlatformService {
    private _platform = inject(PLATFORM_ID);
    public isBrowser = isPlatformBrowser(this._platform);
    public isServer = isPlatformServer(this._platform);
}
