export abstract class StoryblokError extends Error {}

export class MissingComponentStroyblokError extends StoryblokError {
    constructor(componentName: string) {
        super(
            `Missing configuration for component named ${componentName}`,
        );
    }
}

export class StroyblokApiError extends StoryblokError {
    constructor(
        endpoint: string,
        public error?: unknown,
    ) {
        super(
            `Storyblok Api Error: ${endpoint} returned an error`,
        );
    }
}
